import { useState } from "react";
import fuzzysort from "fuzzysort";
import Script from 'next/script'

import eviDO from "@assets/evildo.svg";
import Head from "next/head";
import Link from "next/link";
import { pagination, fetchWithCache } from "@util/config";

import Nav from "@components/Nav";
import Search from "@components/Search";
import Dobadge from "@components/Dobadge";

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const Home = (props) => {
  const { records } = props;
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageMax, setPageMax] = useState(Math.round(records.length / pageSize));
  const [tutorials, setTutorials] = useState(
    paginate(records, pageSize, pageNumber)
  );
  const [results, setResults] = useState(null);

  const loadMore = () => {
    const data = results
      ? paginate(results, pageSize, currentPage + 1)
      : paginate(records, pageSize, currentPage + 1);
    setTutorials((prev) => [...prev, ...data]);
    setCurrentPage(currentPage + 1);
  };

  const searching = (e) => {
    const value = e.toLowerCase();
    const newRecords = fuzzysort.go(
      value,
      records.map((r) => r.title),
      { all: true }
    );
    const combine = newRecords.map((r) =>
      records.find((nr) => nr.title === r.target)
    );
    setResults(combine);
    setTerm(e);
    setCurrentPage(1);
    setTutorials(paginate(combine, pageSize, pageNumber));
    setPageMax(Math.round(combine.length / pageSize));
    console.log(value, 'tut: ',combine)
  };

  console.log('tut', tutorials)
  return (
    <>
      <Head>
        <title>Neo DO</title>
        <meta name="description" content="A comprehensive collection of all DO Community Content gathered in a single archive." />
        <link rel="icon" href={eviDO.src} type="image/svg+xml" />
      </Head>
      <Nav />
      <div className="max-w-6xl mx-auto px-4 md:px-2">
        <Dobadge/>
          <Link href={'/csstricks'}>
              Check CSSTricks Archive
          </Link>
        <Search searching={searching} />
        Pages: {pageMax}
        {tutorials.length > 0 && tutorials.map((t) => (
          <h3 className="pb-8" key={t.id}>
            <Link className="font-bold" href={t.slug}>
              {term ? (
                <p
                  className="p-0"
                  dangerouslySetInnerHTML={{
                    __html: fuzzysort.highlight(
                      fuzzysort.single(term, t.title),
                      "<b>",
                      "</b>"
                    ),
                  }}
                />
              ) : (
                <p className="p-0">{t.title}</p>
              )}
            </Link>
            <span className="italic">
              {new Date(t.date).toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </span>
          </h3>
        ))}
        {currentPage < pageMax && (
          <div className="cursor-pointer" onClick={() => loadMore()}>
            Load More ...
          </div>
        )}
      </div>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MC2286VS66"
        strategy="afterInteractive"
      ></script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-MC2286VS66');`}
      </Script>
    </>
  );
};

export default Home;

export async function getStaticProps() {
  const limit = pagination.limit;
  let page = 0;
  let tutorials = [];

  for (let step = 0; step < 9; step++) {
    await fetchWithCache(
      `https://www.digitalocean.com/api/static-content/v1/tutorials?limit=${limit}&page=${page}`
    )
      .then((data) => {
        tutorials = tutorials.concat(data.data);
        page = page + 1;
      })
      .catch((e) => {});
  }

  if (!tutorials) return { notFound: true };

    const filterTutorials = tutorials.filter((obj, index, self) =>
        index === self.findIndex((o) => o.id === obj.id)
    );
  return {
    props: {
      records: filterTutorials
        .map((t) => ({
          id: t.id,
          title: t.title,
          slug: t.slug,
          type: t.tutorial_type,
          state: t.state,
          date: t.published_at,
          last_validated_at: t.last_validated_at,
        }))
          .sort((a, b) =>  b.id - a.id),
    },
  };
}
