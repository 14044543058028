import { useState, useEffect } from "react";

import Head from "next/head";
import Link from "next/link";

import useDarkMode from "@hooks/useDarkMode";
import logo from "@assets/logo.png";
import logoDark from "@assets/logo-dark.png";
import moon from "@assets/moon.svg";
import sun from "@assets/sun.svg";

const Nav = (props) => {
  const { data } = props;
  const [colorTheme, setTheme] = useDarkMode();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    mounted && (
      <div className="max-w-6xl mx-auto pb-12 px-4 md:px-0 flex justify-between">
        <Link href="/">
          <img
            src={colorTheme === "light" ? logoDark.src : logo.src}
            alt="neoDO"
          />
        </Link>
        <div className="cursor-pointer">
          <img
            className="h-6"
            src={colorTheme === "light" ? moon.src : sun.src}
            onClick={() =>
              setTheme((prev) => (prev === "light" ? "dark" : colorTheme))
            }
            alt="neoDO"
          />
        </div>
      </div>
    )
  );
};

export default Nav;
