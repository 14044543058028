
const Dobadge = (props) => {

return (
<div className="z-20 rounded-md bg-white p-2 fixed text-black right-4 bottom-4">
	<svg className="text-[#0069ff] h-4 w-4 inline-block mr-2 rotate-180" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1567 1562" width="1567" height="1562">
		<title>logo</title>
		<path id="Layer" fill-rule="evenodd" fill="currentColor" d="m784.5 1562v-302.5c322.4 0 570.3-317.3 447.3-655-44.7-124.3-145.4-224.5-270.2-269-339.2-122.5-657.8 126.1-657.8 445.3h-303.8c0-510.3 495.7-909.2 1032.4-742.2 234.8 72.3 421.2 259.7 495.7 493.6 167.7 536.2-231.1 1029.8-743.6 1029.8zm-301.9-601.2h301.9v300.6h-301.9zm-232.9 300.6h232.9v231.9h-232.9zm-195.6 0.1v-193h193.8v193z"/>
	</svg>

  Prodly Hosted on Digital Ocean
</div>);
}

export default Dobadge