const Search = (props) => {
  const { searching } = props;

  return (
    <div className="pb-12">
      <div className="relative mt-1 flex items-center">
        <input
          onChange={(e) => searching(e.target.value)}
          type="text"
          name="search"
          id="search"
          placeholder="Enter the Matrix ….."
          className="text-black block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-green-700 focus:ring-green-700 sm:text-sm"
        />
        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
            ⌘S
          </kbd>
        </div>
      </div>
    </div>
  );
};

export default Search;
